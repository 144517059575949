<template>
  <div :class="['tiptap-editor tiptap-text-editor', { error: error }]">
    <div class="tiptap-toolbar">
      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.bold')"
        class="btn"
        :class="{
          active: isActive('bold'),
        }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <i class="far fa-fw fa-bold" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.italic')"
        class="btn"
        :class="{
          active: isActive('italic'),
        }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <i class="far fa-fw fa-italic" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.underline')"
        class="btn"
        :class="{
          active: isActive('underline'),
        }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <i class="far fa-fw fa-underline" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.strikethrough')"
        class="btn"
        :class="{
          active: isActive('strike'),
        }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <i class="far fa-fw fa-strikethrough" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.numbered_list')"
        class="btn"
        :class="{
          active: isActive('orderedList'),
        }"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <i class="far fa-fw fa-list-ol" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.bulleted_list')"
        class="btn"
        :class="{
          active: isActive('bulletList'),
        }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <i class="far fa-fw fa-list-ul" />
      </button>

      <button
        v-be-tooltip="$t('components.form.text_editor.tooltips.link')"
        class="btn"
        :class="{
          active: isActive('link'),
        }"
        @click="toggleLink"
      >
        <i class="far fa-fw fa-link" />
      </button>

      <button
        v-be-tooltip="
          $t('components.form.text_editor.tooltips.remove_formatting')
        "
        class="btn"
        @click="editor.chain().focus().unsetAllMarks().run()"
      >
        <i class="far fa-fw fa-text-slash" />
      </button>

      <button
        v-if="withColumns"
        v-be-tooltip="$t('components.form.text_editor.tooltips.columns')"
        class="btn"
        :class="{
          active: isActive('text-columns'),
        }"
        @click="toggleTextColumns"
      >
        <i class="far fa-fw fa-line-columns" />
      </button>

      <button
        v-if="withTable"
        v-be-tooltip="$t('components.form.text_editor.tooltips.table')"
        class="btn"
        :class="{
          active: isActive('table'),
        }"
        @click="addTable"
      >
        <i class="far fa-fw fa-table-cells" />
      </button>

      <div v-if="isActive('table')" class="button-group">
        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.add_column')"
          class="btn"
          :class="{
            active: isActive('bold'),
          }"
          @click="editor.chain().focus().addColumnAfter().run()"
        >
          <i class="far fa-fw fa-distribute-spacing-horizontal" />
        </button>

        <button
          v-be-tooltip="
            $t('components.form.text_editor.tooltips.remove_column')
          "
          class="btn"
          :class="{
            active: isActive('bold'),
          }"
          @click="editor.chain().focus().deleteColumn().run()"
        >
          <i class="far fa-fw fa-xmarks-lines fa-rotate-270" />
        </button>

        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.add_row')"
          class="btn"
          @click="editor.chain().focus().addRowAfter().run()"
        >
          <i class="far fa-fw fa-distribute-spacing-vertical" />
        </button>

        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.remove_row')"
          class="btn"
          @click="editor.chain().focus().deleteRow().run()"
        >
          <i class="far fa-fw fa-xmarks-lines" />
        </button>

        <button
          v-be-tooltip="$t('components.form.text_editor.tooltips.remove_table')"
          class="btn"
          @click="editor.chain().focus().deleteTable().run()"
        >
          <i class="fas fa-fw fa-times" />
        </button>
      </div>
    </div>

    <bubble-menu
      v-if="editor"
      :editor="editor"
      :tippy-options="{ duration: 100, placement: 'bottom' }"
      plugin-key="link-bubble-menu"
      :should-show="
        ({ editor: localEditor, view, state, oldState, from, to }) => {
          return tiptapIsActive(state, 'link');
        }
      "
    >
      <div class="bubble-menu bg-body border rounded p-2">
        <div v-if="editor.getAttributes('link').href" class="mb-1">
          {{ `${$t("components.shared.tiptap_text_editor.visit_url")}:` }}
          <be-link :href="editor.getAttributes('link').href">
            {{ truncateText(editor.getAttributes("link").href, 32) }}
          </be-link>
        </div>

        <be-form-group
          class="mb-0 gap-2"
          :label="`${$t('components.shared.tiptap_text_editor.link')}:`"
          label-for="link-href"
          inline
        >
          <be-form-input
            id="link-href"
            :model-value="editor.getAttributes('link').href"
            @change="setLink"
          />

          <be-button
            size="sm"
            variant="danger"
            @click="
              editor.chain().focus().extendMarkRange('link').unsetLink().run()
            "
          >
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </be-form-group>
      </div>
    </bubble-menu>

    <editor-content
      :class="{ 'columns-active': isActive('text-columns') }"
      :editor="editor"
    />

    <slot name="footer" />
  </div>
</template>

<script>
import { BubbleMenu, Editor, EditorContent } from "@tiptap/vue-3";
import { isActive } from "@tiptap/core";

import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import ListKeymap from "@tiptap/extension-list-keymap";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { TextColumnsNode } from "./TextColumnsNode";
import { HashtagMention } from "./hashtag-extension.js";

import { truncateText } from "@/utils/text-utils";

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },

  props: {
    content: {
      type: String,
      default: "",
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    withColumns: {
      type: Boolean,
      default: false,
    },

    withTable: {
      type: Boolean,
      default: false,
    },

    withHashtags: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["update:content"],

  data() {
    return {
      editor: null,
      initialized: false,
    };
  },

  watch: {
    content(value) {
      if (this.editor.getHTML() === value) {
        return;
      }

      if (
        document.activeElement !==
        this.editor.options.element.querySelector(".ProseMirror")
      ) {
        this.editor.commands.setContent(value, false);
      }
    },

    readonly() {
      this.initializeTiptap();
    },
  },

  mounted() {
    this.initializeTiptap();
  },

  beforeUnmount() {
    this.editor?.destroy();
  },

  methods: {
    truncateText,

    initializeTiptap() {
      const extensions = [
        Bold,
        BulletList,
        Document,
        Dropcursor,
        Gapcursor,
        HardBreak,
        History,
        HorizontalRule,
        Italic,
        Link.configure({
          openOnClick: false,
        }),
        ListItem,
        ListKeymap,
        OrderedList,
        Paragraph,
        Strike,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Text,
        TextStyle,
        Underline,
      ];

      if (this.withHashtags) {
        extensions.push(HashtagMention);
      }
      if (this.withColumns) {
        extensions.push(TextColumnsNode);
      }

      this.editor?.destroy();

      this.editor = new Editor({
        content: this.content || "",
        editable: !this.readonly,
        extensions: extensions,

        onUpdate: ({ editor }) => {
          if (this.initialized) {
            this.$emit("update:content", editor.getHTML());
          }
        },
      });

      this.initialized = true;
    },

    isActive(type) {
      return this.editor?.isActive(type);
    },

    tiptapIsActive: isActive,

    toggleLink() {
      if (this.isActive("link")) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
      } else {
        this.setLink(null);
      }
    },

    setLink(url) {
      let href = url || "";
      const { view, state } = this.editor;
      const { from, to } = view.state.selection;
      const textSelection = state.doc.textBetween(from, to, "");

      if (url === null) {
        if (textSelection !== "") {
          href = textSelection;
        } else {
          return;
        }
      }

      // empty, remove link
      if (href === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      const protocol = href.slice(0, href.indexOf(":"));
      if (!["http", "https", "mailto", "tel"].includes(protocol)) {
        href = "http://" + href;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: href })
        .run();
    },

    toggleTextColumns() {
      this.editor.chain().focus().toggleTextColumns().run();
    },

    addTable() {
      if (!this.isActive("table")) {
        this.editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
          .run();
      }
    },
  },
};
</script>
